import http from '@/utils/request'

/**
 * 问题类型
 * @returns
 */
export const getProblemCatList = () => {
  return http.get('/problem/problem-cat-list')
}

/**
 * 提交问题反馈
 * @returns
 */
export const submitProblemFeedback = (data = {}) => {
  return http.post('/problem/submit-feedback', data)
}

/**
 * 反馈问题列表
 * @returns
 */
export const getProblemList = (params = {}) => {
  return http.get('/problem/list', params)
}

/**
 * 反馈问题详情
 * @returns
 */
export const getProblemDetail = (params = {}) => {
  return http.get('/problem/detail', params)
}
