<template>
  <div class="container">
    <div class="reply-list">
      <div class="reply-item self">
        <div class="title">
          <img class="avatar" :src="user.avatar" />
          <div class="name">{{ user.name }}</div>
        </div>
        <div class="content">
          <div>
            <div class="chat-title">问题描述</div>
            <div class="problem-desc">{{ problemDetail.problem_desc }}</div>
          </div>
          <div style="margin-top: 10px">
            <div class="chat-title">问题截图</div>
            <div class="pictures" v-if="!problemDetail.problem_pictures.length">无</div>
            <div class="pictures" v-if="problemDetail.problem_pictures.length">
              <van-image
                class="picture"
                @click="imagePreview(index)"
                fit="cover"
                width="80"
                height="80"
                v-for="(picture, index) in problemDetail.problem_pictures"
                :key="index"
                :src="picture"
              >
                <template v-slot:loading><van-loading /></template
              ></van-image>
            </div>
          </div>
        </div>
        <div class="time">2022-19-12 12:00:21</div>
      </div>

      <div class="reply-item" v-for="reply in replyList" :key="reply.id" :class="[reply.type == 1 ? '' : 'self']">
        <div class="title">
          <img :src="reply.type == 1 ? '/images/common/self-avatar.jpg' : user.avatar" class="avatar" />
          <div class="name">{{ reply.type == 1 ? '月之底客服' : user.name }}</div>
        </div>
        <div class="content">{{ reply.content }}</div>
        <div class="time">{{ reply.created_at }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackDetail',
}
</script>
<script setup>
import { Image, ImagePreview, Loading } from 'vant'
import { ref, onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { getProblemDetail } from '@/api/problem'
import config from '@/config'

const VanImage = Image
const VanImagePreview = ImagePreview.Component
const VanLoading = Loading
const store = useStore()
const route = useRoute()

const problemId = ref(0)
const user = computed(() => store.state.user.info)
const problemDetail = ref({ problem_pictures: [] })
const replyList = ref([])

onMounted(() => {
  initData()
})

const initData = () => {
  store.dispatch('user/getInfo')
  store.dispatch('player/getInfo')
  problemId.value = route.query.id || 0
  getProblemDetail({ problemId: problemId.value }).then(res => {
    problemDetail.value = res.data.problemDetail
    replyList.value = res.data.replyList
  })
}

const imagePreview = index => {
  ImagePreview({ images: problemDetail.value.problem_pictures, startPosition: index })
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.reply-list {
  padding: 20px 10px;
}
.reply-item {
  width: 100%;
  margin-bottom: 15px;
}

.reply-item .title {
  display: flex;
  margin-bottom: 10px;
}

.reply-item.self .title {
  flex-direction: row-reverse;
}

.reply-item .avatar {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
.reply-item .name {
  line-height: 35px;
  margin-left: 10px;
  color: #d3d9e7;
  font-size: 14px;
}
.reply-item.self .name {
  margin-right: 10px;
}
.reply-item .content {
  font-size: 14px;
  background: #242329;
  position: relative;
  color: #b6b7cb;
  padding: 18px 20px;
  border-radius: 7px;
  width: 90vw;
  margin-bottom: 5px;
}

.reply-item.self .content {
  background: #2e2e36;
  float: right;
}
.reply-item .content:after {
  bottom: 100%;
  left: 15px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #242329;
  border-width: 10px;
  margin-left: -10px;
}

.reply-item.self .content:after {
  left: auto;
  right: 5px;
  border-bottom-color: #2e2e36;
}
.chat-title {
  color: var(--app-title-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.picture {
  margin-right: 10px;
}
.reply-item .time {
  color: #7a7c89;
  font-size: 13px;
  margin-left: 5px;
}

.reply-item.self .time {
  text-align: right;
  margin-right: 5px;
}

.pictures {
  display: flex;
}
.van-image {
  padding: 0 10px;
}
</style>
